<template>
  <div class="smartStation-map">
    <div class="data-panel">
      <el-select
          class="select-country"
          v-model="formInfo.data.id"
          filterable
          @change="fetchStationInfoList"
          collapse-tags
          multiple
          clearable
          remote
          :remote-method="remoteMethod"
          placeholder="请输入电站名称"
      >
        <template v-for="item in listTypeInfo.stationList">
          <el-option
              :key="item.value + '_' + item.label"
              :label="item.label"
              :value="item.value"
          />
        </template>
        <template #prefix>
          <div class="select-country-prefix">
            <i class="el-icon-map-location"></i>
          </div>
        </template>
      </el-select>
      <div class="station-list">
        <div class="station-list_item"
             v-for="item in list"
             :key="item.label"
        >
          <div class="icon">
            <el-image :src="item.icon" />
          </div>
          <div class="content">
            <div class="count">{{ item.value }}</div>
            <div class="label-unit">
              <div class="label">{{ item.label }}</div>
              <div class="unit">/{{ item.unit }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="device-run_status">
        <div class="title">设备运行状态</div>
        <div class="run-status-item">
          <div class="title-num">
            <div class="title">正常</div>
            <div class="count">{{ information.onlineDeviceNum || 0 }}</div>
          </div>
          <el-progress :stroke-width="8" :percentage="50" :show-text="false" color="#33cea0" />
        </div>
        <div class="run-status-item">
          <div class="title-num">
            <div class="title">故障</div>
            <div class="count">{{ information.offlineDeviceNum || 0 }}</div>
          </div>
          <el-progress :stroke-width="8" :percentage="20" :show-text="false" color="red" />
        </div>
      </div>
    </div>
    <div class="mapBox">
      <Echart
          id="smartStationMapBox"
          ref="smartStationMapRef"
          :options="options"
          height="100%"
          width="100%"
      />
    </div>
  </div>
</template>
<script>
import nameMap from "@/common/map/nameMap.json"
import Echart from "@/common/echart/index.vue";
// import {geoChinaCoordMap, geoWorldCoordMap} from "../../common/map/geoCoordMap";
import SiteDetail from "./siteDetail.vue";
import Vue from "vue";
const SiteDetailConstructor = Vue.extend(SiteDetail)
const countryList = Object.entries(nameMap).map(([label, value]) => {
  return {
    label, value
  }
})
export default {
  name: 'smartStation-map',
  components: { Echart },
  inject: ['rootCom'],
  data() {
    return {
      countryList,
      country: 'China',
      options: {},
      preSelect: null,
      listTypeInfo: {
        stationList: []
      },
      formInfo: {
        data: {
          id: []
        }
      },
      points: []
    }
  },
  computed: {
    list() {
      const { onlinePowerStationNum, offlinePowerStationNum, abnormalPowerStationNum } = this.information
      return [
        {
          label: '在线电站',
          value: onlinePowerStationNum || 0,
          unit: '座',
          icon: require('@/assets/smartStation/qbzx.png')
        },
        {
          label: '部分离线电站',
          value: offlinePowerStationNum || 0,
          unit: '座',
          icon: require('@/assets/smartStation/bflx.png')
        },
        {
          label: '离线电站',
          value: abnormalPowerStationNum || 0,
          unit: '座',
          icon: require('@/assets/smartStation/qblx.png')
        },
      ]
    },
    information() {
      return this.rootCom.information || {}
    }
  },
  created() {
    this.remoteMethod('')
    this.fetchStationInfoList()
    // this.$nextTick(() => {
    //   this.selectMap()
    // })
  },
  methods: {
    genFormData() {
      const { id, ...rest } = this.formInfo.data
      return {
        id: id.join(','),
        ...rest
      }
    },
    async fetchStationInfoList() {
      await this.$get('aiot-devicecenter/project/home/mapList', this.genFormData())
          .then(res => {
            if (res.data.code === '200') {
              const { data = [] } = res.data
              this.points = data
            } else {
              this.points = []
            }
          })
          .catch(() => {
            this.points = []
          })
      this.initChart()
    },
    remoteMethod(val) {
      // this.listTypeInfo.stationList = const2list(temp, 'string')
      this.$get('aiot-devicecenter/project/home/idAndNameList', {
        field: 'createTime',
        name: val,
        pageSize: 100,
        pageNum: 1
      }).then(res => {
        if (res.data.code === '200') {
          const records = res.data.data.records || []
          this.listTypeInfo.stationList = records.map(son => {
            return {
              label: son.name,
              value: son.id
            }
          })
        } else {
          this.listTypeInfo.stationList = []
        }
      })
    },
    // changeCountry() {
    //   this.selectMap()
    // },
    // selectMap() {
    //   const { country, preSelect } = this
    //   const map = this.$refs.smartStationMapRef.chart
    //   const cur = countryList.find(item => item.value === country)
    //   if (cur) {
    //     const name = cur.label
    //     const coord = geoWorldCoordMap[name]
    //     if (coord) map.setOption({
    //       geo: {
    //         center: coord
    //       }
    //     })
    //   }
    //   if (preSelect) {
    //     map.dispatchAction({
    //       type: "geoUnSelect",
    //       name: preSelect,
    //     })
    //   }
    //   map.dispatchAction({
    //     type: "geoSelect",
    //     name: country
    //   })
    //
    //   this.preSelect = country
    // },
    initChart() {
      const points = this.points
      const seriesData = points.filter(son => son.longitude && son.latitude).map(item => {
        return {
          name: item.name,
          value: [item.longitude, item.latitude, item]
        }
      })
      this.options = {
        showLegendSymbol: true,
        tooltip: {
          trigger: "item",
          backgroundColor: '#fff',
          textStyle: {
            fontSize: 14,
            lineHeight: 22,
          },
          formatter: (params) => {
            const tip = new SiteDetailConstructor({
              propsData: {
                rowData: params.value[2]
              }
            })
            tip.$mount()
            return tip.$el.outerHTML
          },
          appendTo: 'body'
        },
        // visualMap: {
        //   //将数值的大小映射到明暗度
        //   min: 0,
        //   max: 10,
        //   show: false,
        //   seriesIndex: 0,
        //   // 颜色
        //   inRange: {
        //     color: ["rgba(23,240,146,0.5)", "rgba(244,32,16,0.9)"],
        //   },
        // },
        geo: {
          // center: geoWorldCoordMap["中国"],
          zoom: 1.3,
          roam: true,
          map: "world",
          itemStyle: {
            normal: {
              color: "red",
              areaColor: "rgba(19,54,162, .5)",
              borderColor: "rgba(0,242,252,.3)",
              borderWidth: 1,
              shadowBlur: 7,
              shadowColor: "#00f2fc",
            },
            emphasis: {
              areaColor: "rgba(0,242,252,.6)",
              borderColor: "rgba(0,242,252,.6)",
              borderWidth: 2,
              shadowBlur: 10,
              shadowColor: "#00f2fc",
            },
          }
        },
        series: [
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: seriesData,
            encode: {
              value: 2
            },
            symbolSize: 12,
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true
              },
              emphasis: {
                show: true
              }
            },
            itemStyle: {
              color: '#53e843',
              shadowBlur: 10,
              shadowColor: '#333'
            }
          }
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@use './mixins.scss';
.smartStation-map {
  width: 100%;
  height: 654px;
  position: relative;

  .data-panel {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    padding-left: 10px;

    .select-country {
      width: 300px;
      background: transparent;
      ::v-deep .el-input {
        font-size: 20px !important;
        .el-input__inner {
          background: transparent;
          color: mixins.$color-primary;
          border-image: linear-gradient(to right, #33cea0, #a0e5d0) 1;
          height: 40px !important;
        }
        .el-select__caret {
          color: mixins.$color-primary;
        }
      }

      .select-country-prefix {
        height: 100%;
        display: flex;
        align-items: center;
        color: mixins.$color-primary;
      }
    }

    .station-list {
      width: 250px;
      margin-top: 20px;
      .station-list_item {
        display: flex;
        padding: 10px 0;
        .icon {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          //justify-content: center;
          .el-image {
            width: 30px;
            height: 30px;
          }
        }

        .content {
          display: flex;
          align-items: center;
          .count {
            font-size: mixins.$big-font-size;
            margin-right: 10px;
          }
          .label-unit {
            color: #8d8b8b;
          }
        }
      }
    }

    .device-run_status {
      width: 250px;
      margin-top: 50px;
      & > .title {
        color: #c3c0c0;
        font-size: mixins.$title-font-size;
        margin-bottom: 20px;
      }

      .run-status-item {
        .title-num {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          height: 40px;
          & > .title {
            color: #c3c0c0;
            margin-right: 20px;
            align-self: end;
          }
          & > .count {
            color: #d1dff2;
            font-size: mixins.$big-font-size;
            line-height: 1.25;
            height: 100%;
          }
        }
      }
    }
  }

  .mapBox {
    width: 100%;
    height: 100%;
  }
}

::v-deep .el-select__tags {
  max-width: 85% !important;
  padding-left: 25px;
}
</style>
