<template>
  <div class="smartStation" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else>
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">{{ title || '' }}数字能源平台</span>
<!--              <dv-decoration-6-->
<!--                  class="dv-dec-6"-->
<!--                  :reverse="true"-->
<!--                  :color="['#50e3c2', '#67a1e5']"-->
<!--              />-->
            </div>
            <dv-decoration-8
                class="dv-dec-8"
                :reverse="true"
                :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>
        <el-row>
          <el-col :span="19">
            <smart-station-map />
            <ele-energy-analysis ref="analysisRef" />
          </el-col>
          <el-col :span="5">
            <div class="smartStation-right">
              <div class="smart-station-info">
                <div class="header">
                  <div class="title">
                    电站总数
                    <div class="currentMonth">
                      本月<i style="color: red;" class="el-icon-top" />{{ information.moonProjectNum || 0 }}
                    </div>
                  </div>
                  <div class="station-num">{{ information.totalPowerStationNum || 0 }}</div>
                </div>
                <div class="statistics">
                  <el-row :gutter="20">
                    <el-col :span="14" class="left">
                      <div class="left-item">
                        <div class="title-num">
                          <div class="title">户用</div>
                          <div class="count">{{ information.householdPowerStationNum || 0 }}</div>
                        </div>
                        <el-progress
                            :stroke-width="8"
                            :percentage="calcPercentage(information.totalPowerStationNum, information.householdPowerStationNum)"
                            :show-text="false"
                            color="#ffc107"
                        />
                      </div>
                      <div class="left-item">
                        <div class="title-num">
                          <div class="title">商用</div>
                          <div class="count">{{ information.businessPowerStationNum || 0 }}</div>
                        </div>
                        <el-progress
                            :stroke-width="8"
                            :percentage="calcPercentage(information.totalPowerStationNum, information.businessPowerStationNum)"
                            :show-text="false"
                            color="#ffc107"
                        />
                      </div>
                    </el-col>
                    <el-col :span="8" class="right">
                      <div class="right-item">
                        <div class="value-unit">
                          <div class="value">{{ information.totalPVCapacity || 0 }}</div>
                          <div class="unit">kWh</div>
                        </div>
                        <div class="title">总光伏容量</div>
                      </div>
                      <div class="right-item">
                        <div class="value-unit">
                          <div class="value">{{ information.totalESCapacity || 0 }}</div>
                          <div class="unit">kWh</div>
                        </div>
                        <div class="title">总储能容量</div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <el-row class="power-generation" :gutter="10">
                  <el-col :span="12" class="power-generation_item">
                    <div class="value-unit">
                      <div class="value">{{ information.todayFullHours || 0 }}</div>
                      <div class="unit">H</div>
                    </div>
                    <div class="title">
                      今日满发小时
                      <dv-decoration-4
                          :reverse="true"
                          style="width:100%; height:5px; margin-top: 10px"
                      />
                    </div>
                  </el-col>
                  <el-col :span="12" class="power-generation_item">
                    <div class="value-unit">
                      <div class="value">{{ information.powerGeneration || 0 }}</div>
                      <div class="unit">H</div>
                    </div>
                    <div class="title">
                      实时发电功率
                      <dv-decoration-4
                          :reverse="true"
                          style="width:100%; height:5px; margin-top: 10px"
                      />
                    </div>
                  </el-col>
                </el-row>
                <div class="content">
                  <el-row :gutter="10">
                    <template v-for='item in contentList'>
                      <el-col class="content-item_box" :span="12" :key="item.label">
                        <div class="content-item">
                          <div class="icon"></div>
                          <div class="num-label">
                            <div class="num">{{ item.value }}</div>
                            <div class="label">
                              {{ item.label }}<span class="unit">/{{ item.unit }}</span>
                            </div>
                          </div>
                        </div>
                      </el-col>
                    </template>
                  </el-row>
                </div>
              </div>
              <div class="eleRanking">
                <div class="header">
                  <dv-border-box-8 style="height: 100%">
                    <div class="header-box">
                      <i class="el-icon-menu" />
                      <div class="title">电站排名</div>
                    </div>
                  </dv-border-box-8>
                </div>
                <div class="filter">
                  <el-radio-group
                      size="mini"
                      v-model="type"
                      @change="getRankList"
                  >
                    <template v-for="item in list">
                      <el-radio-button :label="item.value" :key="item.value">
                        {{ item.label }}
                      </el-radio-button>
                    </template>
                  </el-radio-group>

                  <el-date-picker
                      size="mini"
                      style="margin-left: 6px"
                      :type="calcDateItem.dateType"
                      v-model="dateValue"
                  />
                </div>
                <div class="rankingBox">
                  <div ref="scrollRef">
                    <div
                        class="rankItem"
                        v-for="(item, index) in rankList"
                        :key="index">
                      <div class="title-num">
                        <div class="title">{{ item.projectName || '-' }}</div>
                        <div class="count">{{ item.chargeCapacity || 0 }}kWh</div>
                      </div>
                      <el-progress
                          :stroke-width="14"
                          :percentage="item.percentage || 0"
                          :show-text="false"
                          color="rgb(231 212 85)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import drawMixin from "@/utils/drawMixin";
import SmartStationMap from "@/views/smartStation/smartStationMap.vue";
import EleEnergyAnalysis from "@/views/smartStation/eleEnergyAnalysis.vue";
import * as seamless from 'seamscroll'
import {mapGetters} from "vuex";
import netConstants from "@/net/netConstants";
import moment from "moment/moment";
import calc from 'calculatorjs'
export default {
  name: 'smartStation',
  components: {EleEnergyAnalysis, SmartStationMap},
  mixins: [drawMixin],
  provide() {
    return {
      rootCom: this
    }
  },
  data() {
    return {
      loading: true,
      type: 1,
      dateValue: Date.now(),
      list: [
        {
          label: '日',
          value: 1,
          dateType: "date",
          getFormData: () => {
            const dateValue =  this.dateValue || Date.now()
            return {
              period: '1h',
              startTime: moment(dateValue).startOf('d').valueOf(),
              endTime: moment(dateValue).endOf('d').valueOf()
            }
          },
          formatXData: (time) => time ? moment(time).format('HH:mm') : '-',
        },
        {
          label: '月',
          value: 3,
          dateType: "month",
          getFormData: () => {
            const dateValue =  this.dateValue || Date.now()
            return {
              period: '1d',
              startTime: moment(dateValue).startOf('month').valueOf(),
              endTime: moment(dateValue).endOf('month').valueOf()
            }
          },
          formatXData: (time) => time ? moment(time).format('D日') : '-'
        },
        {
          label: '年',
          value: 4,
          dateType: "year",
          getFormData: () => {
            const dateValue =  this.dateValue || Date.now()
            return {
              period: '1n',
              startTime: moment(dateValue).startOf('year').valueOf(),
              endTime: moment(dateValue).endOf('year').valueOf()
            }
          },
          formatXData: (time) => time ? moment(time).format('M月') : '-'
        }
      ],
      rankList: [],
      information: {},
      title: '',
      decorationColor: ["#568aea", "#000000"],
    }
  },
  watch: {
    rankList: {
      handler(val) {
        if (val && val.length > 5) {
          setTimeout(() => {
            seamless.init({
              dom: this.$refs.scrollRef,
              step: 5,
              singleHeight: 60
            })
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    calcDateItem() {
      const { type } = this;
      return this.list.find(item => item.value === type);
    },
    contentList() {
      const information = this.information;
      return [
          {
            label: '今日发电量',
            value: information.generation || 0,
            unit: 'kWh'
          },
          {
            label: '累计发电量',
            value: information.totalGeneration || 0,
            unit: 'kWh'
          },
          {
            label: '今日充电量',
            value: information.chargeCapacity || 0,
            unit: 'kWh'
          },
          {
            label: '累计充电量',
            value: information.totalChargeCapacity || 0,
            unit: 'kWh'
          },
          {
            label: '今日放电量',
            value: information.dischargeAmount || 0,
            unit: 'kWh'
          },
          {
            label: '累计放电量',
            value: information.totalDischargeAmount || 0,
            unit: 'kWh'
          },
          // {
          //   label: '总光伏容量',
          //   value: information.totalPVCapacity || 0,
          //   unit: 'kWh'
          // },
          // {
          //   label: '总储能容量',
          //   value: information.totalESCapacity || 0,
          //   unit: 'kWh'
          // }
      ]
    },
    ...mapGetters(['info'])
  },
  mounted() {
    this.title = this.$route.query.name;
    setTimeout(() => {
      this.loading = false
      this.getToken(this.$route.params.id)
    }, 500)
  },
  beforeDestroy() {
    if (this.siv) {
      clearInterval(this.siv)
      this.siv = null
    }
  },
  methods: {
    getToken(key) {
      this.$post(netConstants.api_screen_getToken, { key: decodeURIComponent(key) })
          .then((res) => {
            let data = res.data.data;
            this.$store.commit('setInfo', data)
            this.initData()
            this.siv = setInterval(() => {
              this.initData()
            }, 1000 * 60 * 60 * 30)
          })
          .catch((error) => {
            console.log("getToken", error);
          });
    },
    initData() {
      this.getInformation()
      this.getTrends()
      this.getRankList()
    },
    getRankList() {
      this.rankList = []
      this.$get('aiot-datacenter/data/charge/trends/ranking', {
        merchantId: this.info.merchantId,
        mac: '',
        powerMode: 1,
        timezoneOffset: this.getTimezoneOffset(),
        ...this.calcDateItem.getFormData()
      }).then(res => {
        if (res.data.code === '200' && res.data.data) {
          let total = 0
          const data = res.data.data || []
          data.forEach(son => {
            total = calc.add(total, son.chargeCapacity || 0)
          })
          this.rankList = data.map(son => {
            return {
              ...son,
              percentage: this.calcPercentage(total, son.chargeCapacity || 0)
            }
          })
        }
      })
    },
    getTrends() {
      this.$refs.analysisRef.getChartData()
    },
    getInformation() {
      this.information = {}
      this.$get('aiot-datacenter/data/information', {
        merchantId: this.info.merchantId
      }).then(res => {
        if (res.data.code === '200' && res.data.data) {
          this.information = res.data.data;
        }
      })
    },
    calcPercentage(total = 0, value = 0) {
      if ((isNaN(+total) || isNaN(+value)) || !(+total)) {
        return 0
      } else {
        return +(calc.div(Number(value), Number(total))  * 100).toFixed(2)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@use "./smartStation.scss";
</style>
