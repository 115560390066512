<template>
  <div class="siteDetail" @click="(e) => e.stopPropagation()">
    <div class="header">{{ rowData.name || '-' }}</div>
    <divider class="divider1" />
    <div class="top-content align-center">
      <div class="site-image" :style="{ background: `center / contain no-repeat url(${ rowData.map || siteImg })` }" />
      <div class="info">
        <div class="info-item">
          <div class="label">电站地址：</div>
          <div class="value">{{ rowData.address || '-' }}</div>
        </div>
        <div class="info-item">
          <div class="label">经纬度：</div>
          <div class="value">{{ numberFormat(rowData.longitude) }}, {{ numberFormat(rowData.latitude) }}</div>
        </div>
        <div class="info-item">
          <div class="label">组串总容量：</div>
          <div class="value">{{ rowData.luminousDepthCapacity || '-' }}kWp</div>
        </div>
        <div class="info-item">
          <div class="label">并网时间：</div>
          <div class="value">{{ rowData.connectGridTime || '-' }}</div>
        </div>
        <div class="info-item">
          <div class="label">运行天数：</div>
          <div class="value">{{ rowData.numberOfDaysOfOperation }}天</div>
        </div>
<!--        <div class="info-item">-->
<!--          <div class="label">电站时区：</div>-->
<!--          <div class="value">(UTC+08:00)北京</div>-->
<!--        </div>-->
<!--        <div class="info-item">-->
<!--          <div class="label">电站时区日期：</div>-->
<!--          <div class="value">2024-06-15</div>-->
<!--        </div>-->
      </div>
    </div>
    <template v-if="rowData.iotDeviceWeatherPO">
      <divider class="divider1" />
      <div class="header">天气预报</div>
      <div style="width: 100%; box-sizing: border-box;">
        <weather :row-data="rowData" />
      </div>
    </template>
  </div>
</template>
<script>
import {
  Divider,
  // Image
} from "element-ui"
import Weather from "./weather.vue";
export default {
  name: 'siteDetail',
  props: {
    rowData: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Weather,
    Divider,
    // ElImage: Image
  },
  computed: {
    numberFormat() {
      return (value) => {
        value = +value
        if (isNaN(value)) return '-'
        const stringVal = '' + value
        const dotIndex = stringVal.indexOf('.')
        return dotIndex > -1 ? stringVal.slice(0, dotIndex + 1 + 4) : stringVal
      }
    }
  },
  data() {
    return {
      siteImg: require('@/assets/Site_img.png')
    }
  }
}
</script>
<style lang="scss">
.siteDetail {
  width: 550px;
  box-sizing: border-box;
  color: #333;
  padding: 10px 14px;
  .header {
    font-size: 18px;
  }
  .divider1 {
    margin: 10px 0;
  }
  .top-content {
    width: 100%;
    display: flex;
    .site-image {
      width: 40%;
      padding: 0 10px;
      background: #f8f8f8;
      align-self: stretch;
    }
    .info {
      width: 60%;
      font-size: 14px;
      .info-item {
        display: flex;
        flex-wrap: nowrap;
        padding-left: 16px;
        margin-bottom: 6px;
        .label {
          flex-shrink: 0;
          white-space: normal;
          word-break: break-all;
          color: #333;
          width: 90px;
        }
        .value {
          flex: 1;
          flex-shrink: 0;
          word-break: break-all;
          white-space: normal;
        }
      }
    }
  }
}
</style>
