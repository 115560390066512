<template>
  <div class="weather">
    <div class="weather-now flex align-center between">
      <div class="main">
        <div class="today">{{ getTimeZone(iotDeviceWeatherPO.weatherUpdateTime, '{y}-{m}-{d}') }}</div>
        <div class="temperature">{{ iotDeviceWeatherPO.tempMin }}~{{ iotDeviceWeatherPO.tempMax }}°C</div>
      </div>
      <div class="nowPic">
        <i :class="'qi-' + iotDeviceWeatherPO.iconDay" />
      </div>
    </div>
    <div class="weather-list flex">
      <div class="weather-day">
        <div class="week">明天</div>
        <div class="temperature">{{ iotDeviceWeatherPO.tomorrowTempMin }}~{{ iotDeviceWeatherPO.tomorrowTempMax }}°C</div>
        <div class="week-pic">
          <i :class="'qi-' + iotDeviceWeatherPO.tomorrowIconDay" />
        </div>
        <div class="temperature">{{ iotDeviceWeatherPO.tomorrow }}</div>
      </div>
      <div class="weather-day">
        <div class="week">后天</div>
        <div class="temperature">{{ iotDeviceWeatherPO.afterTomorrowTempMin }}~{{ iotDeviceWeatherPO.afterTomorrowTempMax }}°C</div>
        <div class="week-pic">
          <i :class="'qi-' + iotDeviceWeatherPO.afterTomorrowIconDay" />
        </div>
        <div class="temperature">{{ iotDeviceWeatherPO.tomorrow }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'weather',
  props: {
    rowData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    iotDeviceWeatherPO() {
      return this.rowData.iotDeviceWeatherPO || {}
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.weather {
  //padding: 14px;
  display: flex;
  //background-color: #f2f4f3;
  .weather-now {
    .main {
      .today {
        color: #999999;
      }
      .temperature {
        font-size: 28px;
        margin: 10px 0;
      }
      .detail {
        color: #999999;
      }
    }
  }
  .nowPic {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    font-size: 40px;
    text-align: center;
    line-height: 80px;
    color: #a2e1bf;
  }

  .weather-list {
    width: 100%;
    padding-top: 20px;
    overflow-y: auto;
    .weather-day {
      width: 100px;
      color: #797878;
      text-align: center;
      .week {}
      .week-pic {
        width: 100%;
        height: 40px;
        color: #a2e1bf;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
      }

      .temperature {
      }
    }
  }
}
</style>
