<template>
  <Echart
      :options="options"
      :id="id"
      height="100%"
      width="100%"
  ></Echart>
</template>
<script>
import Echart from '@/common/echart'
export default {
  name: 'eleEnergyAnalysisChart',
  components: {
    Echart
  },
  data() {
    return {
      options: {}
    }
  },
  props: {
    chartData: {
      type: Object,
      default: () => {
        return {
          xData: [],
          data: []
        }
      }
    },
    id: {
      type: String,
      default: "eleEnergyAnalysisChart"
    }
  },
  watch: {
    chartData: {
      handler() {
        this.options = this.getOptions()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getOptions() {
      const { xData, data } = this.chartData
      const [data1 = [], data2 = [], data3 = []] = data || []
      return {
        color: ['#eeb142', '#5cf4a3', '#49d4f0'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          top: 20,
          itemWidth: 14,
          itemHeight: 14,
          itemGap: 20,
          icon: 'rect'
        },
        grid: {
          top: 80,
          left: 30,
          right: 30,
          bottom: 30
        },
        xAxis: {
          type: 'category',
          data: xData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '光伏发电量',
            data: data1,
            type: 'line',
            smooth: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#eeb142'
                }, {
                  offset: 1, color: 'transparent'
                }],
                global: false
              },
              opacity: 0.3
            }
          },
          {
            name: '储能放电量',
            data: data2,
            type: 'line',
            smooth: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#5cf4a3'
                }, {
                  offset: 1, color: 'transparent'
                }],
                global: false
              },
              opacity: 0.3
            }
          },
          {
            name: '储能充电量',
            data: data3,
            type: 'line',
            smooth: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: '#49d4f0'
                }, {
                  offset: 1, color: 'transparent'
                }],
                global: false
              },
              opacity: 0.3
            }
          }
        ]
      }
    }
  }
}
</script>
