<template>
  <div class="eleEnergyAnalysis">
    <div class="eleEnergyAnalysis-header">
      <dv-border-box-8 style="height: 100%">
        <div class="header-box">
          <div class="title">电能分析</div>
          <div class="right">
            <el-radio-group v-model="type" @change="getChartData">
              <template v-for="item in list">
                <el-radio-button :label="item.value" :key="item.value">
                  {{ item.label }}
                </el-radio-button>
              </template>
            </el-radio-group>

            <el-date-picker
                style="margin-left: 6px"
                :type="calcDateItem.dateType"
                v-model="dateValue"
            />
          </div>
        </div>
      </dv-border-box-8>
    </div>
    <div class="eleEnergyAnalysisChart">
      <ele-energy-analysis-chart :chart-data="chartData" />
    </div>
  </div>
</template>
<script>
import EleEnergyAnalysisChart from "@/views/smartStation/eleEnergyAnalysisChart.vue";
// import { xDays, xMonths, xYears, yDays, yMonths, yYears } from "@/utils/mockChartData";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: 'eleEnergyAnalysis',
  components: { EleEnergyAnalysisChart },
  computed: {
    calcDateItem() {
      const { type } = this;
      return this.list.find(item => item.value === type);
    },
    ...mapGetters(['info'])
  },
  data() {
    return {
      type: 1,
      dateValue: Date.now(),
      list: [
        {
          label: '日',
          value: 1,
          dateType: "date",
          getFormData: () => {
            const dateValue =  this.dateValue || Date.now()
            return {
              period: '1h',
              startTime: moment(dateValue).startOf('d').valueOf(),
              endTime: moment(dateValue).endOf('d').valueOf()
            }
          },
          formatXData: (time) => time ? moment(time).format('HH:mm') : '-',
        },
        {
          label: '月',
          value: 3,
          dateType: "month",
          getFormData: () => {
            const dateValue =  this.dateValue || Date.now()
            return {
              period: '1d',
              startTime: moment(dateValue).startOf('month').valueOf(),
              endTime: moment(dateValue).endOf('month').valueOf()
            }
          },
          formatXData: (time) => time ? moment(time).format('D日') : '-'
        },
        {
          label: '年',
          value: 4,
          dateType: "year",
          getFormData: () => {
            const dateValue =  this.dateValue || Date.now()
            return {
              period: '1n',
              startTime: moment(dateValue).startOf('year').valueOf(),
              endTime: moment(dateValue).endOf('year').valueOf()
            }
          },
          formatXData: (time) => time ? moment(time).format('M月') : '-'
        }
      ],
      chartData: {
        xData: [],
        data: []
      }
    }
  },
  methods: {
    getChartData() {
      this.chartData = {
        xData: [],
        data: []
      }
      this.$get('aiot-datacenter/data/charge/trends', {
        merchantId: this.info.merchantId,
        mac: '',
        powerMode: 1,
        timezoneOffset: this.getTimezoneOffset(),
        ...this.calcDateItem.getFormData()
      }).then(res => {
        if (res.data.code === '200' && res.data.data) {
          let xData = [];
          const yData1 = []
          const yData2 = []
          const yData3 = []
          const data = res.data.data || []
          data.forEach(son => {
            xData.push(this.calcDateItem.formatXData(son.ts))
            yData1.push(son.photovoltaicCharging || 0)
            yData2.push(son.energyStorageDischarge || 0)
            yData3.push(son.energyStorageCharging || 0)
          })
          this.chartData = {
            xData,
            data: [yData1, yData2, yData3]
          }
        }
      })
      // if (value === 1) {
      //   x = xDays
      //   datas = datas.concat([yDays(), yDays(), yDays()])
      // } else if (value === 3) {
      //   x = xMonths
      //   datas = datas.concat([yMonths(), yMonths(), yMonths()])
      // } else if (value === 4) {
      //   x = xYears
      //   datas = datas.concat([yYears(), yYears(), yYears()])
      // }
      // this.chartData = {
      //   xData: x,
      //   data: datas
      // }
    }
  }
}
</script>
<style lang="scss" scoped>
@use './mixins.scss';
.eleEnergyAnalysis {
  width: 100%;
  height: 348px;
  position: relative;

  .eleEnergyAnalysis-header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    .header-box {
      height: 100%;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      & .title {
        font-size: mixins.$title-font-size;
      }
      & > .right {
        position: absolute;
        right: 10px;
        z-index: 1000;
        ::v-deep .el-radio-group {
          .el-radio-button__inner {
            background: transparent;
            color: #fff;
          }
          .el-radio-button__orig-radio:checked+.el-radio-button__inner {
            color: mixins.$color-primary;
            border-color: mixins.$color-primary;
            box-shadow: -1px 0 0 0 mixins.$color-primary;
          }
        }

        ::v-deep .el-date-editor {
          .el-input__inner {
            @include mixins.input-style;
          }
        }
      }
    }
  }

  .eleEnergyAnalysisChart {
    width: 100%;
    height: 100%;
  }
}
</style>
